let withInTrace = false

export const appendTraceToConsoleError = () => {
  if ((console.error as any).appended) return
  const originalConsoleError = console.error
  console.error = (...args: unknown[]) => {
    if (withInTrace) {
      originalConsoleError(...args)
      return
    }
    withInTrace = true
    // eslint-disable-next-line no-console
    console.trace()
    withInTrace = false
    originalConsoleError(...args)
  }
  ;(console.error as any).appended = true
}
