export const hjid = 2530164
export const hjsv = 6

// ref https://engineering.brighthr.com/blog-posts/hotjar-styled-components-fix/
class StyledComponentsCSSInserterForHotJar {
  private static instance: StyledComponentsCSSInserterForHotJar | null = null
  private syncStylesEl: HTMLStyleElement
  private syncHotJarStylesTimeout: number | null = null

  private syncHotJarStyles() {
    // console.log('syncHotJarStyles')
    const styleNodes = ([] as any[]).slice.call(
      document.querySelectorAll('head [data-styled]')
    )
    if (!styleNodes.length) {
      return
    }
    const styles = styleNodes
      .map(({ sheet }) =>
        [].slice
          .call(sheet.cssRules)
          .map((rule: any) => rule.cssText)
          .join(' ')
      )
      .join(' ')
    this.syncStylesEl.textContent = styles
    // console.log('styles:', styles, this.syncStylesEl)
  }

  private debouncedSyncHotJarStyles() {
    // console.log('debouncedSyncHotJarStyles')
    if (this.syncHotJarStylesTimeout != null)
      clearTimeout(this.syncHotJarStylesTimeout)
    this.syncHotJarStylesTimeout = window.setTimeout(
      this.syncHotJarStyles.bind(this),
      1000
    )
  }

  private constructor() {
    // console.log('construct')
    this.syncStylesEl = document.createElement('style')
    this.syncStylesEl.type = 'text/css'
    document.head.insertBefore(this.syncStylesEl, document.head.children[0])

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalInsertRule = CSSStyleSheet.prototype.insertRule
    const debouncedSyncHotJarStyles = this.debouncedSyncHotJarStyles.bind(this)
    CSSStyleSheet.prototype.insertRule = function (style, index) {
      // console.log('insertRule', style, index)
      const res = originalInsertRule.call(this, style, index)
      const hotjar = (window as any).hj
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      // console.log(hotjar, hotjar.settings, hotjar.settings.record)
      if (hotjar && hotjar.settings && hotjar.settings.record) {
        debouncedSyncHotJarStyles()
      }
      return res
    }
  }

  static getInstance() {
    if (!StyledComponentsCSSInserterForHotJar.instance) {
      StyledComponentsCSSInserterForHotJar.instance =
        new StyledComponentsCSSInserterForHotJar()
    }

    return StyledComponentsCSSInserterForHotJar.instance
  }
}

export const setupHotJar = () => {
  StyledComponentsCSSInserterForHotJar.getInstance()
}
